import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/i18n/useTranslation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/lib/auth.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/lib/ExitIntentSurvey.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagProvider"] */ "/vercel/path0/apps/vendor-dashboard/src/lib/FeatureFlagProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/lib/posthogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/lib/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/lib/queryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@ant-design+nextjs-registry@1.0.1_c9e9f3aa514c925cbbd2af0b53b54131/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query-devtools@5.51.24_1ddbde0fe712397ed4199f1e202bd4a0/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/jotai@2.9.3_@types+react@18.3.3+react@18.2.0/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.24_8a2a1eee516835a8040c05f34baf174e/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.24_8a2a1eee516835a8040c05f34baf174e/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/components/index.scss");
