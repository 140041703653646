import { DisplayFile, message } from '@repo/common/components';
import { GetReceivablesUserApplicationListOut, ReceivablesApplicationDocuments, ReceivablesApplicationHistoryFullOut, ReceivablesCompany, ReceivablesCompanyDocuments, ReceivablesCompanyIn, UserChecklistFile, ReceivablesDocumentFilterIn, ReceivablesDocumentUploadIn, ReceivablesDocument } from '@repo/common/dtos';
import otmowService from '@repo/common/services/otmowService';
import { UseMutationResult, queryOptions, useMutation, useQuery, type UseQueryResult, useQueryClient, skipToken } from '@tanstack/react-query';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { usePostHog } from 'posthog-js/react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useContext, useEffect } from 'react';
import { DefaultValues, Path, PathValue, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useIsAuth } from '@/lib/auth';
import { ApplicationContext } from '@/lib/applicationContext';
import { useTranslation } from '../i18n/useTranslation';
import { useCurrentApplicationId, useCurrentCnpj } from './context';

const companiesQuery = queryOptions({
  queryKey: ['companies'],
  queryFn: () => otmowService.receivables.representative.company.getCompanies(),
  refetchOnWindowFocus: false,
  staleTime: 1_000,
});

const companyQuery = (companyId: string | undefined) => queryOptions({
  queryKey: ['company', companyId],
  queryFn: companyId ? () => otmowService.receivables.representative.company.getCompany(companyId) : skipToken,
  refetchOnWindowFocus: false,
  staleTime: 1_000,
});

export function useCompanies(): UseQueryResult<ReceivablesCompany[], Error> {
  const isAuth = useIsAuth();
  const result = useQuery({
    ...companiesQuery,
    enabled: !!isAuth,
  });

  return result;
}

export function useDefaultCompany() {
  const isAuth = useIsAuth();
  const posthog = usePostHog();
  const [defaultCompanyCnpj, setDefaultCompanyCnpj] = useCurrentCnpj();

  const result = useQuery({
    ...companiesQuery,
    enabled: !!isAuth,
    staleTime: 1_000,
    select: (companies) => {
      const newCompany = companies.find((company) => company.cnpj === defaultCompanyCnpj) || companies[0];
      setDefaultCompanyCnpj(newCompany?.cnpj);
      return newCompany;
    },
  });

  useEffect(() => {
    if (result.data && result.data.metadata?.marketingId) {
      posthog.identify(result.data.metadata.marketingId);
    }
  }, [result.data, posthog]);

  return result;
}

const applicationQuery = (applicationId: string | undefined) => queryOptions({
  queryKey: ['application', applicationId],
  queryFn: () => {
    if (!applicationId) {
      throw Error('Application ID not defined.');
    }
    return otmowService.receivables.representative.application.getApplication(applicationId);
  },
  // refetchOnWindowFocus: false,
  staleTime: 1_000,
});

export function useApplication(applicationId: string | undefined): UseQueryResult<ReceivablesApplicationHistoryFullOut, Error> {
  const isAuth = useIsAuth();
  return useQuery({
    ...applicationQuery(applicationId),
    enabled: !!isAuth && !!applicationId,
  });
}

export function useContextApplication(): UseQueryResult<ReceivablesApplicationHistoryFullOut, Error> {
  const posthog = usePostHog();
  const applicationId = useContext(ApplicationContext);
  useEffect(() => {
    if (!applicationId || !applicationId.match(/^\d+$/)) {
      // posthog.capture('application_id_malformed', { applicationId });
      // throw Error('Invalid application ID. Is your component wrapped in an ApplicationContextProvider?');
    }
  }, [applicationId, posthog]);

  return useApplication(applicationId ?? undefined);
}

export function useContextCompany() {
  const applicationDataQuery = useContextApplication();
  const companyId = applicationDataQuery.data?.application.companyId;
  const query = useQuery({
    ...companyQuery(companyId),
  });

  return query;
}

export function useGetApplications(cnpj?: string): UseQueryResult<GetReceivablesUserApplicationListOut, Error> {
  const isAuth = useIsAuth();
  const result = useQuery({
    queryKey: ['applications'],
    queryFn: () => otmowService.receivables.representative.application.getApplications(cnpj),
    refetchOnWindowFocus: false,
    enabled: !!isAuth,
  });

  return result;
}

export function useDefaultApplication(company: ReceivablesCompany | undefined) {
  const [defaultApplicationId, setApplicationId] = useCurrentApplicationId();
  const applicationId = (defaultApplicationId && company?.applications.includes(defaultApplicationId))
    ? defaultApplicationId
    : company?.applications.at(0);

  useEffect(() => {
    if (!defaultApplicationId && applicationId) {
      setApplicationId(applicationId);
    }
  }, [defaultApplicationId, applicationId, setApplicationId]);

  return useApplication(applicationId);
}

export function useApplicationStatus() {
  const {
    data: applicationData,
    isLoading: isLoadingApplication,
  } = useContextApplication();

  return [applicationData?.application.status, isLoadingApplication] as const;
}

export type DocumentName = keyof ReceivablesApplicationDocuments | keyof ReceivablesCompanyDocuments;
export interface UploadDetails {
  uploadPath?: string;
  filename: string;
  documentType: string;
  description?: string;
}

const uploadToBucket = async (uploadRequestOptions: UploadRequestOption & { description?: string }, documentType: DocumentName) => {
  const { file } = uploadRequestOptions;
  const description = uploadRequestOptions.description ?? '';

  if (typeof file !== 'object' || !('uid' in file)) {
    throw Error('Invalid type for file parameter.');
  }
  if (!documentType) {
    throw Error('Filename not set for uploaded file.');
  }

  const storageRef = ref(getStorage(), file.uid);
  const uploadResult = await uploadBytes(storageRef, file);

  const uploadPath = `${uploadResult.metadata.bucket}/${uploadResult.metadata.fullPath}`;
  return {
    uploadPath,
    filename: file.name,
    documentType,
    description,
  } as UploadDetails;
};

export function assignFile(uploadResult: UploadDetails, documentType: DocumentName, application?: ReceivablesApplicationHistoryFullOut) {
  if (!application) {
    throw Error('Must provide application to upload file.');
  }

  if (!uploadResult.uploadPath) {
    throw Error('Must provide upload path to upload file.');
  }

  const filePayload: ReceivablesDocumentUploadIn = {
    gcpFilePath: uploadResult.uploadPath,
    fileName: uploadResult.filename,
    documentType,
    description: uploadResult.description,
    applicationId: application.application.id,
  };
  return otmowService.receivables.document.createWithApplication(filePayload);
}

export type DocumentFilterOptional = Omit<ReceivablesDocumentFilterIn, 'applicationId'> & { applicationId?: string };

export const useGetDocuments = (filters: ReceivablesDocumentFilterIn) => useQuery({
  queryKey: ['documents', filters],
  queryFn: () => otmowService.receivables.document.getDocuments(filters),
  enabled: !!filters.applicationId,
  staleTime: 60_000,
});

type FormOptions<Field extends (keyof ReceivablesApplicationDocuments | keyof ReceivablesCompanyDocuments), Extra extends object> =
  Record<Field, DisplayFile[] | undefined> & Extra;
type Options<T> = {
  application?: ReceivablesApplicationHistoryFullOut;
  relatedEvent: string;
  extraFields?: T,
  onUploaded?: () => unknown;
  isLoading?: boolean;
};
type Return<Name extends (keyof ReceivablesApplicationDocuments | keyof ReceivablesCompanyDocuments), Extra extends object> = {
  form: UseFormReturn<FormOptions<Name, Extra>>;
  isUploading: boolean;
  upload: UseMutationResult<UploadDetails, Error, UploadRequestOption>['mutate'];
  directUpload: UseMutationResult<ReceivablesDocument, Error, UploadDetails>['mutate'];
};
export function useUploadDocument<
  // eslint-disable-next-line space-before-function-paren
  const Name extends (
    keyof ReceivablesApplicationDocuments | keyof ReceivablesCompanyDocuments),
  const T extends object
>(
  documentType: Name,
  {
    application = undefined, relatedEvent, extraFields, onUploaded,
  }: Options<T>,
): Return<Name, T> {
  type F = FormOptions<Name, T>;
  const form = useForm<F>({
    defaultValues: {
      [documentType]: undefined,
      ...extraFields,
    } as DefaultValues<F>,
    mode: 'onBlur',
  });
  const { t } = useTranslation();
  const posthog = usePostHog();
  const queryClient = useQueryClient();

  const documentFilters: DocumentFilterOptional = {
    documentType: documentType as string,
    applicationId: application?.application.id,
  };

  if (application) documentFilters.applicationId = application.application.id;
  const getDocumentsResult = useGetDocuments(documentFilters);
  const existingDocument: UserChecklistFile | null = getDocumentsResult.data?.documents?.[0] as (UserChecklistFile | null);

  const isSingleDocument = typeof existingDocument === 'object' && !Array.isArray(existingDocument);

  useEffect(() => {
    if (!existingDocument || !isSingleDocument) return;
    const status = existingDocument.checkList?.status === 'DECLINED' ? 'error' : 'done';
    form.setValue(documentType as unknown as Path<F>, [{
      uid: '-1', // Unique identifier, negative to avoid conflict with actual upload
      name: `${existingDocument.fileName} - ${existingDocument.description}`,
      status,
      url: existingDocument.gcpFilePath,
      message: t(existingDocument.checkList?.message),
    }] as PathValue<F, Path<F>>);
    // without this form.trigger, after refresh pages aren't able to detect that the file is already uploaded
    // and set the isValid state of the form correctly
    form.trigger(documentType as unknown as Path<F>);
  }, [existingDocument, isSingleDocument]);

  const uploadFileMutation = useMutation({
    mutationKey: ['document', 'assign'],
    mutationFn: (uploadResult: UploadDetails) => assignFile(uploadResult, documentType, application),
    onSuccess: (_, uploadResult) => {
      posthog.capture(relatedEvent, {
        applicationId: application?.application.id,
        companyId: application?.application.companyId,
        cnpj: application?.application.cnpj,
      });
      message.success(`${uploadResult.filename} ${t('uploaded successfully!')}`);
      if (onUploaded) {
        onUploaded();
      }
      queryClient.invalidateQueries({ queryKey: ['documents', { applicationId: application?.application.id, companyId: application?.application.companyId, documentType }] });
    },
  });

  const uploadBytesMutation = useMutation({
    mutationKey: ['document', 'upload'],
    mutationFn: async (uploadRequestOptions: UploadRequestOption & { description?: string }) => uploadToBucket(uploadRequestOptions, documentType),
    onSuccess(uploadResult, uploadRequestOptions) {
      if (uploadRequestOptions.onSuccess) {
        uploadRequestOptions.onSuccess(uploadResult.uploadPath);
      }
      uploadFileMutation.mutate(uploadResult);
    },
  });

  return {
    form,
    isUploading: uploadBytesMutation.isPending || uploadFileMutation.isPending,
    upload: uploadBytesMutation.mutate,
    directUpload: uploadFileMutation.mutate,
  };
}

export type DocumentUploadState =
  | 'waiting'
  | 'uploading'
  | 'uploaded'
  | 'assigning'
  | 'assigned'
  | 'error';

export type FileDisplay = {
  filename: string;
  state: DocumentUploadState;
};

export function useMultipleUploadDocument(fileType: DocumentName, application?: ReceivablesApplicationHistoryFullOut) {
  const form = useForm<{
    documents: Array<{
      uploadResult?: UploadDetails;
      file: UploadRequestOption & { description?: string };
      state: DocumentUploadState;
      error?: unknown;
    }>;
    currentDocument?: object;
  }>();
  const { fields, append, update, remove } = useFieldArray({
    control: form.control,
    name: 'documents',
  });

  const onSubmit = form.handleSubmit((data) => {
    const allUploaded = data.documents.every((field) => field.state === 'uploaded');
    if (allUploaded) {
      const uploads = Promise.all(data.documents.map((field, index) => {
        const result = field.uploadResult;
        if (result) {
          update(index, {
            ...field,
            state: 'assigning',
          });
          return assignFile(result, fileType, application).then(() => {
            update(index, {
              ...field,
              state: 'assigned',
            });
          }).catch((error: unknown) => {
            update(index, {
              ...field,
              state: 'error',
              error,
            });
          });
        }
        return Promise.resolve();
      }));
      return uploads;
    }
    return Promise.reject(new Error('Some files are not uploaded yet.'));
  });

  useEffect(() => {
    if (fields.length === 0) {
      return;
    }
    fields.forEach((field, index) => {
      if (field.state === 'waiting') {
        update(index, {
          ...field,
          state: 'uploading',
        });
        uploadToBucket(field.file, fileType).then((uploadResult) => {
          update(index, {
            ...field,
            state: 'uploaded',
            uploadResult,
          });
        }).catch((error: unknown) => {
          update(index, {
            ...field,
            state: 'error',
            error,
          });
        });
      }
    });
  }, [fields, form, fileType, update]);

  function addFile(file: UploadRequestOption & { description?: string }) {
    append({
      file,
      state: 'waiting',
    });
  }

  function removeFile(index: number) {
    remove(index);
  }

  return {
    files: fields,
    addFile,
    removeFile,
    submitFiles: onSubmit,
    control: form.control,
    register: form.register,
  };
}

export function useApproveLoanProposal() {
  return useMutation({
    mutationFn: ({ applicationId, anticipatedRate }: { applicationId: string, anticipatedRate: string }) => otmowService.receivables.representative.application.updateLimitedBordero(applicationId, { anticipatedRate, userApproved: true }),
  });
}

export function useCreateApplication() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (companyId: string) => otmowService.receivables.representative.company.createApplication(companyId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['applications'] });
    },
  });
}

export function useCreateCompany() {
  return useMutation({
    mutationFn: (data: ReceivablesCompanyIn) => otmowService.receivables.representative.company.createCompany(data),
  });
}

export function useGetDocumentURL() {
  return useMutation({
    mutationFn: (gcpFilePath: string) => otmowService.receivables.representative
      .getDocument(gcpFilePath),
  });
}

export function useMarkNoDebtDocument() {
  return useMutation({
    mutationFn: (applicationId: string) => otmowService.receivables.document.markNoDebtDocument(applicationId),
  });
}
